<template>
  <div class="p-2" style="overflow-x: hidden">
    <div class="px-2">
      <!-- Product View Icon start -->

      <div class="roundedContainer p-3">
        <div class="mb-3">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="32" height="32" rx="16" fill="#F1F1F4" />
            <path
              d="M18.5 11.8333V15.1666H19.3333V16.8333H16.8333V10.1666H18.5L16 6.83331L13.5 10.1666H15.1667V16.8333H12.6667V15.1083C13.25 14.8 13.6667 14.2083 13.6667 13.5C13.6667 12.4833 12.8417 11.6666 11.8333 11.6666C10.8167 11.6666 10 12.4833 10 13.5C10 14.2083 10.4167 14.8 11 15.1083V16.8333C11 17.2753 11.1756 17.6993 11.4882 18.0118C11.8007 18.3244 12.2246 18.5 12.6667 18.5H15.1667V21.0416C14.575 21.3416 14.1667 21.9583 14.1667 22.6666C14.1667 23.1529 14.3598 23.6192 14.7036 23.963C15.0475 24.3068 15.5138 24.5 16 24.5C16.4862 24.5 16.9525 24.3068 17.2964 23.963C17.6402 23.6192 17.8333 23.1529 17.8333 22.6666C17.8333 21.9583 17.425 21.3416 16.8333 21.0416V18.5H19.3333C19.7754 18.5 20.1993 18.3244 20.5118 18.0118C20.8244 17.6993 21 17.2753 21 16.8333V15.1666H21.8333V11.8333H18.5Z"
              fill="#B3AFB6"
            />
          </svg>
        </div>

        <p class="sidebarElementTitle" style="font-size: 14px">Product Match</p>
        <p class="sidebarElementSubTitle">
          Add or Import Products from your store in our Product Match page
        </p>

        <Button @click="goToConfiguration('product_match')" class="mt-2 w-100"
          >Go to Product Match
          <svg
            class="ml-2"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.7141 5.28597L5.28602 14.7141M14.7141 5.28597L14.7141 12.357M14.7141 5.28597L7.64304 5.28597"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Button>
      </div>

      <div class="my-2 pb-2" style="border-bottom: 1px solid #18191c14">
        <p class="sidebarElementTitle">Type of Cards</p>

        <div class="row">
          <div class="col-6 mb-2">
            <div
              class="roundedContainer d-flex flex-column align-items-center justify-content-center cursor-pointer py-4"
              :class="getViewTypeValue === 'detailOnly' ? 'selected ' : null"
              @click="setViewType('detailOnly')"
            >
              <svg
                class="productCardSVG"
                :class="getViewTypeValue === 'detailOnly' ? 'selected ' : null"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.91667 3H13.5833C15.8833 3 17.75 4.86667 17.75 7.16667V13.8333C17.75 16.1333 15.8833 18 13.5833 18H6.91667C4.61667 18 2.75 16.1333 2.75 13.8333V7.16667C2.75 4.86667 4.61667 3 6.91667 3ZM6.91667 4.66667C5.53333 4.66667 4.41667 5.78333 4.41667 7.16667V13.8333C4.41667 15.2167 5.53333 16.3333 6.91667 16.3333H13.5833C14.9667 16.3333 16.0833 15.2167 16.0833 13.8333V7.16667C16.0833 5.78333 14.9667 4.66667 13.5833 4.66667H6.91667Z"
                  fill="#73738D"
                />
              </svg>

              <span
                class="sidebarElementSubTitle"
                style="font-weight: 500; margin-top: 5px"
                :style="
                  getViewTypeValue === 'detailOnly' ? 'color:#4d1b7e' : null
                "
              >
                Single</span
              >
            </div>
          </div>
          <div class="col-6 mb-2">
            <div
              class="roundedContainer d-flex flex-column align-items-center justify-content-center cursor-pointer py-4"
              :class="getViewTypeValue === 'list' ? 'selected ' : null"
              @click="setViewType('list')"
            >
              <svg
                class="productCardSVG"
                :class="getViewTypeValue === 'list' ? 'selected ' : null"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_448_1842)">
                  <path
                    d="M6.58333 0.5H4.08333C3.19928 0.5 2.35143 0.85119 1.72631 1.47631C1.10119 2.10143 0.75 2.94928 0.75 3.83333L0.75 6.33333C0.75 7.21739 1.10119 8.06524 1.72631 8.69036C2.35143 9.31548 3.19928 9.66667 4.08333 9.66667H6.58333C7.46739 9.66667 8.31524 9.31548 8.94036 8.69036C9.56548 8.06524 9.91667 7.21739 9.91667 6.33333V3.83333C9.91667 2.94928 9.56548 2.10143 8.94036 1.47631C8.31524 0.85119 7.46739 0.5 6.58333 0.5ZM8.25 6.33333C8.25 6.77536 8.07441 7.19928 7.76184 7.51184C7.44928 7.82441 7.02536 8 6.58333 8H4.08333C3.64131 8 3.21738 7.82441 2.90482 7.51184C2.59226 7.19928 2.41667 6.77536 2.41667 6.33333V3.83333C2.41667 3.39131 2.59226 2.96738 2.90482 2.65482C3.21738 2.34226 3.64131 2.16667 4.08333 2.16667H6.58333C7.02536 2.16667 7.44928 2.34226 7.76184 2.65482C8.07441 2.96738 8.25 3.39131 8.25 3.83333V6.33333Z"
                    fill="#73738D"
                  />
                  <path
                    d="M17.4167 0.5H14.9167C14.0327 0.5 13.1848 0.85119 12.5597 1.47631C11.9346 2.10143 11.5834 2.94928 11.5834 3.83333V6.33333C11.5834 7.21739 11.9346 8.06524 12.5597 8.69036C13.1848 9.31548 14.0327 9.66667 14.9167 9.66667H17.4167C18.3008 9.66667 19.1486 9.31548 19.7737 8.69036C20.3989 8.06524 20.75 7.21739 20.75 6.33333V3.83333C20.75 2.94928 20.3989 2.10143 19.7737 1.47631C19.1486 0.85119 18.3008 0.5 17.4167 0.5ZM19.0834 6.33333C19.0834 6.77536 18.9078 7.19928 18.5952 7.51184C18.2827 7.82441 17.8587 8 17.4167 8H14.9167C14.4747 8 14.0508 7.82441 13.7382 7.51184C13.4256 7.19928 13.25 6.77536 13.25 6.33333V3.83333C13.25 3.39131 13.4256 2.96738 13.7382 2.65482C14.0508 2.34226 14.4747 2.16667 14.9167 2.16667H17.4167C17.8587 2.16667 18.2827 2.34226 18.5952 2.65482C18.9078 2.96738 19.0834 3.39131 19.0834 3.83333V6.33333Z"
                    fill="#73738D"
                  />
                  <path
                    d="M6.58333 11.3333H4.08333C3.19928 11.3333 2.35143 11.6845 1.72631 12.3096C1.10119 12.9347 0.75 13.7826 0.75 14.6666L0.75 17.1666C0.75 18.0507 1.10119 18.8985 1.72631 19.5237C2.35143 20.1488 3.19928 20.5 4.08333 20.5H6.58333C7.46739 20.5 8.31524 20.1488 8.94036 19.5237C9.56548 18.8985 9.91667 18.0507 9.91667 17.1666V14.6666C9.91667 13.7826 9.56548 12.9347 8.94036 12.3096C8.31524 11.6845 7.46739 11.3333 6.58333 11.3333ZM8.25 17.1666C8.25 17.6087 8.07441 18.0326 7.76184 18.3452C7.44928 18.6577 7.02536 18.8333 6.58333 18.8333H4.08333C3.64131 18.8333 3.21738 18.6577 2.90482 18.3452C2.59226 18.0326 2.41667 17.6087 2.41667 17.1666V14.6666C2.41667 14.2246 2.59226 13.8007 2.90482 13.4881C3.21738 13.1756 3.64131 13 4.08333 13H6.58333C7.02536 13 7.44928 13.1756 7.76184 13.4881C8.07441 13.8007 8.25 14.2246 8.25 14.6666V17.1666Z"
                    fill="#73738D"
                  />
                  <path
                    d="M17.4167 11.3333H14.9167C14.0327 11.3333 13.1848 11.6845 12.5597 12.3096C11.9346 12.9347 11.5834 13.7826 11.5834 14.6666V17.1666C11.5834 18.0507 11.9346 18.8985 12.5597 19.5237C13.1848 20.1488 14.0327 20.5 14.9167 20.5H17.4167C18.3008 20.5 19.1486 20.1488 19.7737 19.5237C20.3989 18.8985 20.75 18.0507 20.75 17.1666V14.6666C20.75 13.7826 20.3989 12.9347 19.7737 12.3096C19.1486 11.6845 18.3008 11.3333 17.4167 11.3333ZM19.0834 17.1666C19.0834 17.6087 18.9078 18.0326 18.5952 18.3452C18.2827 18.6577 17.8587 18.8333 17.4167 18.8333H14.9167C14.4747 18.8333 14.0508 18.6577 13.7382 18.3452C13.4256 18.0326 13.25 17.6087 13.25 17.1666V14.6666C13.25 14.2246 13.4256 13.8007 13.7382 13.4881C14.0508 13.1756 14.4747 13 14.9167 13H17.4167C17.8587 13 18.2827 13.1756 18.5952 13.4881C18.9078 13.8007 19.0834 14.2246 19.0834 14.6666V17.1666Z"
                    fill="#73738D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_448_1842">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0.75 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <span
                class="sidebarElementSubTitle"
                style="font-weight: 500; margin-top: 5px"
                :style="getViewTypeValue === 'list' ? 'color:#4d1b7e' : null"
              >
                Multiple</span
              >
            </div>
          </div>
          <div class="col-6 mb-2">
            <div
              class="roundedContainer d-flex flex-column align-items-center justify-content-center cursor-pointer py-4"
              :class="getViewTypeValue === 'detailList' ? 'selected ' : null"
              @click="setViewType('detailList')"
            >
              <svg
                class="productCardSVG"
                :class="getViewTypeValue === 'detailList' ? 'selected ' : null"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_448_1850)">
                  <path
                    d="M9.41667 1.33331H4C1.9325 1.33331 0.25 3.01581 0.25 5.08331V7.99998C0.25 8.91915 0.9975 9.66665 1.91667 9.66665H9.41667C10.3358 9.66665 11.0833 8.91915 11.0833 7.99998V2.99998C11.0833 2.08081 10.3358 1.33331 9.41667 1.33331ZM1.91667 7.99998V5.08331C1.91667 3.93415 2.85167 2.99998 4 2.99998H9.41667V7.99998H1.91667ZM16.5 2.99998H14.4167C13.4975 2.99998 12.75 3.74748 12.75 4.66665V15.5C12.75 16.4191 13.4975 17.1666 14.4167 17.1666H16.5C18.5675 17.1666 20.25 15.4841 20.25 13.4166V6.74998C20.25 4.68248 18.5675 2.99998 16.5 2.99998ZM18.5833 13.4166C18.5833 14.5658 17.6483 15.5 16.5 15.5H14.4167V4.66665H16.5C17.6483 4.66665 18.5833 5.60081 18.5833 6.74998V13.4166ZM9.41667 11.3333H3.58333C2.66417 11.3333 1.91667 12.0808 1.91667 13V15.9166C1.91667 17.9841 3.59917 19.6666 5.66667 19.6666H9.41667C10.3358 19.6666 11.0833 18.9191 11.0833 18V13C11.0833 12.0808 10.3358 11.3333 9.41667 11.3333ZM9.41667 18H5.66667C4.51833 18 3.58333 17.0658 3.58333 15.9166V13H9.41667V18Z"
                    fill="#73738D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_448_1850">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0.25 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <span
                class="sidebarElementSubTitle"
                style="font-weight: 500; margin-top: 5px"
                :style="
                  getViewTypeValue === 'detailList' ? 'color:#4d1b7e' : null
                "
              >
                Hierarchy</span
              >
            </div>
          </div>
          <div class="col-6 mb-2">
            <div
              class="roundedContainer d-flex flex-column align-items-center justify-content-center cursor-pointer py-4"
              :class="getViewTypeValue === 'groupProducts' ? 'selected ' : null"
              @click="setViewType('groupProducts')"
            >
              <svg
                class="productCardSVG"
                :class="
                  getViewTypeValue === 'groupProducts' ? 'selected ' : null
                "
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_448_1854)">
                  <path
                    d="M16.5833 0.5H4.91667C2.61917 0.5 0.75 2.36917 0.75 4.66667V16.3333C0.75 18.6308 2.61917 20.5 4.91667 20.5H16.5833C18.8808 20.5 20.75 18.6308 20.75 16.3333V4.66667C20.75 2.36917 18.8808 0.5 16.5833 0.5ZM19.0833 16.3333C19.0833 17.7117 17.9617 18.8333 16.5833 18.8333H4.91667C3.53833 18.8333 2.41667 17.7117 2.41667 16.3333V4.66667C2.41667 3.28833 3.53833 2.16667 4.91667 2.16667H16.5833C17.9617 2.16667 19.0833 3.28833 19.0833 4.66667V16.3333ZM8.66667 3.83333H5.33333C4.64417 3.83333 4.08333 4.39417 4.08333 5.08333V8.41667C4.08333 9.10583 4.64417 9.66667 5.33333 9.66667H8.66667C9.35583 9.66667 9.91667 9.10583 9.91667 8.41667V5.08333C9.91667 4.39417 9.35583 3.83333 8.66667 3.83333ZM8.25 8H5.75V5.5H8.25V8ZM16.1667 3.83333H12.8333C12.1442 3.83333 11.5833 4.39417 11.5833 5.08333V8.41667C11.5833 9.10583 12.1442 9.66667 12.8333 9.66667H16.1667C16.8558 9.66667 17.4167 9.10583 17.4167 8.41667V5.08333C17.4167 4.39417 16.8558 3.83333 16.1667 3.83333ZM15.75 8H13.25V5.5H15.75V8ZM8.66667 11.3333H5.33333C4.64417 11.3333 4.08333 11.8942 4.08333 12.5833V15.9167C4.08333 16.6058 4.64417 17.1667 5.33333 17.1667H8.66667C9.35583 17.1667 9.91667 16.6058 9.91667 15.9167V12.5833C9.91667 11.8942 9.35583 11.3333 8.66667 11.3333ZM8.25 15.5H5.75V13H8.25V15.5ZM16.1667 11.3333H12.8333C12.1442 11.3333 11.5833 11.8942 11.5833 12.5833V15.9167C11.5833 16.6058 12.1442 17.1667 12.8333 17.1667H16.1667C16.8558 17.1667 17.4167 16.6058 17.4167 15.9167V12.5833C17.4167 11.8942 16.8558 11.3333 16.1667 11.3333ZM15.75 15.5H13.25V13H15.75V15.5Z"
                    fill="#73738D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_448_1854">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0.75 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <span
                class="sidebarElementSubTitle"
                style="font-weight: 500; margin-top: 5px"
                :style="
                  getViewTypeValue === 'groupProducts' ? 'color:#4d1b7e' : null
                "
              >
                Group Products</span
              >
            </div>
          </div>
          <div class="col-6 mb-2">
            <div
              class="roundedContainer d-flex flex-column align-items-center justify-content-center cursor-pointer py-4"
              :class="getViewTypeValue === 'flipCards' ? 'selected ' : null"
              @click="setViewType('flipCards')"
            >
              <svg
                class="productCardSVG"
                :class="getViewTypeValue === 'flipCards' ? 'selected ' : null"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_448_1854)">
                  <path
                    d="M16.5833 0.5H4.91667C2.61917 0.5 0.75 2.36917 0.75 4.66667V16.3333C0.75 18.6308 2.61917 20.5 4.91667 20.5H16.5833C18.8808 20.5 20.75 18.6308 20.75 16.3333V4.66667C20.75 2.36917 18.8808 0.5 16.5833 0.5ZM19.0833 16.3333C19.0833 17.7117 17.9617 18.8333 16.5833 18.8333H4.91667C3.53833 18.8333 2.41667 17.7117 2.41667 16.3333V4.66667C2.41667 3.28833 3.53833 2.16667 4.91667 2.16667H16.5833C17.9617 2.16667 19.0833 3.28833 19.0833 4.66667V16.3333ZM8.66667 3.83333H5.33333C4.64417 3.83333 4.08333 4.39417 4.08333 5.08333V8.41667C4.08333 9.10583 4.64417 9.66667 5.33333 9.66667H8.66667C9.35583 9.66667 9.91667 9.10583 9.91667 8.41667V5.08333C9.91667 4.39417 9.35583 3.83333 8.66667 3.83333ZM8.25 8H5.75V5.5H8.25V8ZM16.1667 3.83333H12.8333C12.1442 3.83333 11.5833 4.39417 11.5833 5.08333V8.41667C11.5833 9.10583 12.1442 9.66667 12.8333 9.66667H16.1667C16.8558 9.66667 17.4167 9.10583 17.4167 8.41667V5.08333C17.4167 4.39417 16.8558 3.83333 16.1667 3.83333ZM15.75 8H13.25V5.5H15.75V8ZM8.66667 11.3333H5.33333C4.64417 11.3333 4.08333 11.8942 4.08333 12.5833V15.9167C4.08333 16.6058 4.64417 17.1667 5.33333 17.1667H8.66667C9.35583 17.1667 9.91667 16.6058 9.91667 15.9167V12.5833C9.91667 11.8942 9.35583 11.3333 8.66667 11.3333ZM8.25 15.5H5.75V13H8.25V15.5ZM16.1667 11.3333H12.8333C12.1442 11.3333 11.5833 11.8942 11.5833 12.5833V15.9167C11.5833 16.6058 12.1442 17.1667 12.8333 17.1667H16.1667C16.8558 17.1667 17.4167 16.6058 17.4167 15.9167V12.5833C17.4167 11.8942 16.8558 11.3333 16.1667 11.3333ZM15.75 15.5H13.25V13H15.75V15.5Z"
                    fill="#73738D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_448_1854">
                    <rect
                      width="20"
                      height="20"
                      fill="white"
                      transform="translate(0.75 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <span
                class="sidebarElementSubTitle"
                style="font-weight: 500; margin-top: 5px"
                :style="
                  getViewTypeValue === 'flipCards' ? 'color:#4d1b7e' : null
                "
              >
                Flip Cards</span
              >
            </div>
          </div>
          <div
            class="my-2 col-12"
            v-if="
              Object.prototype.hasOwnProperty.call(
                block,
                `useSeperateMobileViewType`
              ) && !isDesktop
            "
          >
            <div class="d-flex align-items-center">
              <div
                class="show-logo custom-control custom-switch cursor-pointer"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="`useSeperateMobileViewType`"
                  v-model="block.useSeperateMobileViewType"
                />

                <label
                  class="custom-control-label"
                  :for="`useSeperateMobileViewType`"
                ></label>
              </div>
              <span
                class="sidebarElementTitle ml-2"
                style="font-size: 14px; font-weight: 500"
                >Use Seperate Mobile View</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- Product View Icon end -->

      <!--Group Products Accordion Start -->
      <div
        class="my-2 pb-3"
        v-if="getViewTypeValue == 'groupProducts'"
        style="border-bottom: 1px solid #18191c14"
      >
        <div
          class="d-flex align-items-center justify-content-between cursor-pointer"
          @click="updateSelectedBlock('groupProductsSettings')"
        >
          <span class="sidebarElementTitle">Group Products Settings</span>
          <div>
            <svg
              v-if="selectedBlock == 'groupProductsSettings'"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.8167 12.8417L10 9.025L6.175 12.8417L5 11.6667L10 6.66667L15 11.6667L13.8167 12.8417Z"
                fill="#73738D"
              />
            </svg>
            <svg
              v-else
              style="transform: rotate(180deg)"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.8167 12.8417L10 9.025L6.175 12.8417L5 11.6667L10 6.66667L15 11.6667L13.8167 12.8417Z"
                fill="#73738D"
              />
            </svg>
          </div>
        </div>
        <div class="my-2" v-if="selectedBlock == 'groupProductsSettings'">
          <div class="mt-1">
            <span
              class="sidebarElementSubTitle"
              style="font-size: 14px; font-weight: 500"
              >Group By</span
            >

            <div class="d-flex align-items-center">
              <select
                class="w-100 newSelectInput ml-2"
                aria-label="Default select example"
                v-model="block.style.bulletDescriptionfontFamily"
              >
                <option value="Tags">Tags</option>
                <option
                  v-if="checkIsCustomCollectionMedisClient"
                  value="Collections"
                >
                  Collections
                </option>
              </select>
            </div>

            <div class="pb-3 mt-2" style="border-bottom: 1px solid #18191c14">
              <div class="d-flex align-items-center justify-content-between">
                <span
                  class="sidebarElementTitle"
                  style="font-size: 14px; font-weight: 500"
                  >Limit Group Products</span
                >

                <div
                  class="show-logo custom-control custom-switch cursor-pointer"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    :id="`limitGroupProduct-${Date.now()}`"
                    v-model="block.groupProducts.limitProductsPerGroup"
                  />

                  <label
                    class="custom-control-label"
                    :for="`limitGroupProduct-${Date.now()}`"
                  ></label>
                </div>
              </div>

              <div v-if="block.groupProducts.limitProductsPerGroup">
                <div class="mt-2">
                  <span
                    class="sidebarElementSubTitle"
                    style="font-size: 14px; font-weight: 500"
                    >Max Group Products</span
                  >

                  <div class="mt-2">
                    <input
                      type="number"
                      min="0"
                      v-model="block.groupProducts.productsPerGroup"
                      class="form-control-sm px-2 ml-2 input-border-style"
                      style="width: 50px; max-width: 50px"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="my-2">
              <div v-if="isFetchingGroupsLoading">
                <div
                  class="sidebarElementTitle m-0 d-flex align-items-center"
                  style="font-size: 14px; font-weight: 500"
                >
                  Fetching your {{ getGroupBy }} groups
                  <div
                    class="spinner-border spinner-border-sm ml-3"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>

              <div v-else class="GroupSelectContainer" style="max-width: 265px">
                <span
                  class="sidebarElementSubTitle mb-2"
                  style="font-size: 14px; font-weight: 500"
                >
                  Select your {{ getGroupBy }} group
                </span>

                <multiselect
                  v-if="groupProducts.length"
                  :options="getGroupOptions"
                  :multiple="true"
                  v-model="block.groupProducts.groupData"
                  class="multiSelect p-0"
                  :placeholder="`Search ${getGroupBy}`"
                  :close-on-select="true"
                  :searchable="true"
                >
                </multiselect>
                <p
                  class="sidebarElementSubTitle m-0"
                  style="font-size: 14px; font-weight: 500"
                  v-else
                >
                  No groups found
                </p>
              </div>
            </div>

            <div class="my-2">
              <span
                class="sidebarElementSubTitle mb-2"
                style="font-size: 14px; font-weight: 500"
                >Group Products Title Styles</span
              >
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    title="Font"
                  >
                    <path
                      d="M2.08333 3.33334V5.83334H6.24999V15.8333H8.74999V5.83334H12.9167V3.33334H2.08333ZM17.9167 7.50001H10.4167V10H12.9167V15.8333H15.4167V10H17.9167V7.50001Z"
                      fill="#B3AFB6"
                    />
                  </svg>

                  <select
                    class="w-50 newSelectInput ml-2"
                    aria-label="Default select example"
                    v-model="block.style.groupProductsStyle.fontFamily"
                  >
                    <option
                      v-for="font in getFontFamilyList"
                      :key="font.value"
                      :value="font.value"
                    >
                      {{ font.value }}
                    </option>
                  </select>
                </div>
                <div class="d-flex align-items-center">
                  <span
                    :class="[
                      {
                        'active-svg':
                          block.style.groupProductsStyle.textAlign === 'left',
                      },
                      'font-style-button',
                      'mx-1 px-2',
                    ]"
                    type="button"
                    @click="block.style.groupProductsStyle.textAlign = 'left'"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.22223 9.2222H0.333344V10.7037H9.22223V9.2222ZM9.22223 3.29628H0.333344V4.77776H9.22223V3.29628ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                        fill="#B3AFB6"
                      />
                    </svg>
                  </span>
                  <span
                    :class="[
                      {
                        'active-svg':
                          block.style.groupProductsStyle.textAlign === 'center',
                      },
                      'font-style-button',
                      'mx-1 px-2',
                    ]"
                    type="button"
                    @click="block.style.groupProductsStyle.textAlign = 'center'"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.29631 9.2222V10.7037H10.7037V9.2222H3.29631ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM3.29631 3.29628V4.77776H10.7037V3.29628H3.29631ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                        fill="#B3AFB6"
                      />
                    </svg>
                  </span>
                  <span
                    :class="[
                      {
                        'active-svg':
                          block.style.groupProductsStyle.textAlign === 'end',
                      },
                      'font-style-button',
                      'mx-1 px-2',
                    ]"
                    type="button"
                    @click="block.style.groupProductsStyle.textAlign = 'end'"
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.166656 11.8334H11.8333V10.5371H0.166656V11.8334ZM4.05555 9.24076H11.8333V7.94447H4.05555V9.24076ZM0.166656 6.64817H11.8333V5.35187H0.166656V6.64817ZM4.05555 4.05558H11.8333V2.75928H4.05555V4.05558ZM0.166656 0.166687V1.46298H11.8333V0.166687H0.166656Z"
                        fill="#B3AFB6"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center justify-content-between my-2">
              <div class="d-flex align-items-center">
                <svg
                  width="19"
                  height="13"
                  viewBox="0 0 19 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z"
                    fill="#B3AFB6"
                  />
                  <path
                    d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z"
                    fill="#B3AFB6"
                  />
                </svg>

                <input
                  type="number"
                  min="0"
                  v-model="block.style.groupProductsStyle.fontSize"
                  class="form-control-sm px-2 ml-2 input-border-style"
                  style="width: 50px; max-width: 50px"
                />
              </div>
              <div class="d-flex align-items-center">
                <span
                  title="Bold"
                  class="mr-1 cursorPointer font-style-button"
                  :class="{
                    'active-svg':
                      block.style.groupProductsStyle.fontWeight === 'bold',
                  }"
                  @click="
                    block.style.groupProductsStyle.fontWeight =
                      block.style.groupProductsStyle.fontWeight == 'normal'
                        ? 'bold'
                        : 'normal'
                  "
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 10 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.00001 5.99165C8.80834 5.43331 9.37501 4.51665 9.37501 3.66665C9.37501 1.78331 7.91668 0.333313 6.04168 0.333313H0.833344V12H6.70001C8.44168 12 9.79168 10.5833 9.79168 8.84165C9.79168 7.57498 9.07501 6.49165 8.00001 5.99165ZM3.33334 2.41665H5.83334C6.52501 2.41665 7.08334 2.97498 7.08334 3.66665C7.08334 4.35831 6.52501 4.91665 5.83334 4.91665H3.33334V2.41665ZM6.25001 9.91665H3.33334V7.41665H6.25001C6.94168 7.41665 7.50001 7.97498 7.50001 8.66665C7.50001 9.35831 6.94168 9.91665 6.25001 9.91665Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
                <span
                  title="Italic"
                  class="mx-1 cursorPointer font-style-button"
                  :class="{
                    'active-svg':
                      block.style.groupProductsStyle.fontStyle === 'italic',
                  }"
                  @click="
                    block.style.groupProductsStyle.fontStyle =
                      block.style.groupProductsStyle.fontStyle == 'normal'
                        ? 'italic'
                        : 'normal'
                  "
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 10 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.33333 0.333313V2.83331H5.175L2.325 9.49998H0V12H6.66667V9.49998H4.825L7.675 2.83331H10V0.333313H3.33333Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
                <span
                  title="Underline"
                  class="mx-1 cursorPointer font-style-button"
                  :class="{
                    'active-svg':
                      block.style.groupProductsStyle.textDecoration ===
                      'underline',
                  }"
                  @click="
                    block.style.groupProductsStyle.textDecoration =
                      block.style.groupProductsStyle.textDecoration ==
                      'underline'
                        ? 'unset'
                        : 'underline'
                  "
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 12 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.99999 12.1667C8.75832 12.1667 11 9.925 11 7.16667V0.5H8.91666V7.16667C8.91666 8.775 7.60832 10.0833 5.99999 10.0833C4.39166 10.0833 3.08332 8.775 3.08332 7.16667V0.5H0.99999V7.16667C0.99999 9.925 3.24166 12.1667 5.99999 12.1667ZM0.166656 13.8333V15.5H11.8333V13.8333H0.166656Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div class="my-2">
              <span
                class="sidebarElementSubTitle"
                style="font-size: 14px; font-weight: 500"
                >Title Color</span
              >

              <div
                class="d-flex align-items-center justify-content-between my-2"
              >
                <div class="d-flex align-items-center">
                  <input
                    type="color"
                    v-model="block.style.groupProductsStyle.color"
                    class="colorInput"
                  />
                  <input
                    type="text"
                    class="form-control-sm px-2 ml-2 input-border-style"
                    style="max-width: 80px"
                    v-model="block.style.groupProductsStyle.color"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Group Products Accordion End -->
      <!--Flip CArds Accordion Start -->

      <div
        class="my-2 pb-3"
        v-if="getViewTypeValue == 'flipCards'"
        style="border-bottom: 1px solid #18191c14"
      >
        <div
          class="d-flex align-items-center justify-content-between cursor-pointer"
          @click="updateSelectedBlock('flipCardsSettings')"
        >
          <span class="sidebarElementTitle">Flip Cards Settings</span>
          <div>
            <svg
              v-if="selectedBlock == 'flipCardsSettings'"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.8167 12.8417L10 9.025L6.175 12.8417L5 11.6667L10 6.66667L15 11.6667L13.8167 12.8417Z"
                fill="#73738D"
              />
            </svg>
            <svg
              v-else
              style="transform: rotate(180deg)"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.8167 12.8417L10 9.025L6.175 12.8417L5 11.6667L10 6.66667L15 11.6667L13.8167 12.8417Z"
                fill="#73738D"
              />
            </svg>
          </div>
        </div>
        <div class="my-2" v-if="selectedBlock == 'flipCardsSettings'">
          <div class="pb-3 mt-2">
            <span
              class="sidebarElementSubTitle"
              style="font-size: 14px; font-weight: 500"
              >Card Height</span
            >

            <div class="d-flex align-items-center mt-2">
              <input
                type="number"
                v-model="block.style.flipCardsHeight"
                min="0"
                placeholder="400"
                class="form-control-sm px-2 input-border-style"
                style="width: 50px; max-width: 60px"
              />
            </div>
          </div>
        </div>
      </div>

      <!--Flip CArds Accordion End -->
      <!--Display Setting Accordion Start -->
      <!-- v-if="getViewTypeValue !== 'groupProducts'" -->
      <div class="my-2 pb-3" style="border-bottom: 1px solid #18191c14">
        <div
          class="d-flex align-items-center justify-content-between cursor-pointer"
          @click="updateSelectedBlock('displaySettings')"
        >
          <span class="sidebarElementTitle">Display Settings</span>
          <div>
            <svg
              v-if="selectedBlock == 'displaySettings'"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.8167 12.8417L10 9.025L6.175 12.8417L5 11.6667L10 6.66667L15 11.6667L13.8167 12.8417Z"
                fill="#73738D"
              />
            </svg>
            <svg
              v-else
              style="transform: rotate(180deg)"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.8167 12.8417L10 9.025L6.175 12.8417L5 11.6667L10 6.66667L15 11.6667L13.8167 12.8417Z"
                fill="#73738D"
              />
            </svg>
          </div>
        </div>
        <div class="my-2" v-if="selectedBlock == 'displaySettings'">
          <div class="mt-1" v-if="getViewTypeValue == 'detailOnly'">
            <div class="pb-3 mt-2" style="border-bottom: 1px solid #18191c14">
              <div class="d-flex align-items-center justify-content-between">
                <span
                  class="sidebarElementTitle"
                  style="font-size: 14px; font-weight: 500"
                  >Show List View</span
                >

                <div
                  class="show-logo custom-control custom-switch cursor-pointer"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    :id="`showListView-${Date.now()}`"
                    v-model="block.showListView"
                  />

                  <label
                    class="custom-control-label"
                    :for="`showListView-${Date.now()}`"
                  ></label>
                </div>
              </div>
            </div>
            <div class="mt-2">
              <div class="d-flex align-items-center justify-content-between">
                <span
                  class="sidebarElementTitle"
                  style="font-size: 14px; font-weight: 500"
                  >Limit Product</span
                >

                <div
                  class="show-logo custom-control custom-switch cursor-pointer"
                >
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    :id="`limitPRoduct-${Date.now()}`"
                    v-model="block.limitProduct"
                  />

                  <label
                    class="custom-control-label"
                    :for="`limitPRoduct-${Date.now()}`"
                  ></label>
                </div>
              </div>

              <div class="my-2" v-if="block.limitProduct">
                <span
                  class="sidebarElementSubTitle"
                  style="font-size: 14px; font-weight: 500"
                  >Max Product</span
                >

                <div class="d-flex align-items-center mt-2">
                  <input
                    type="number"
                    v-model="block.limitProductLength"
                    min="0"
                    class="form-control-sm px-2 input-border-style"
                    style="width: 50px; max-width: 50px"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="mt-1" v-if="getViewTypeValue !== 'detailOnly'">
            <span
              class="sidebarElementSubTitle"
              style="font-size: 14px; font-weight: 500"
            >
              {{
                isDesktop ? "Products per row :" : "Products per row (mobile) :"
              }}</span
            >

            <div class="my-2 row" v-if="isDesktop">
              <div class="col-3">
                <div
                  class="roundedContainer d-flex flex-column align-items-center justify-content-center cursor-pointer py-2"
                  :class="{
                    selected: block.productsPerPage == 1,
                  }"
                  @click="block.productsPerPage = 1"
                >
                  <span
                    class="sidebarElementSubTitle"
                    :style="
                      block.productsPerPage == 1
                        ? 'color:#4d1b7e;font-weight:500;'
                        : null
                    "
                    >1</span
                  >
                </div>
              </div>
              <div class="col-3">
                <div
                  class="roundedContainer d-flex flex-column align-items-center justify-content-center cursor-pointer py-2"
                  :class="{
                    selected: block.productsPerPage == 2,
                  }"
                  @click="block.productsPerPage = 2"
                >
                  <span
                    class="sidebarElementSubTitle"
                    :style="
                      block.productsPerPage == 2
                        ? 'color:#4d1b7e;font-weight:500;'
                        : null
                    "
                    >2</span
                  >
                </div>
              </div>
              <div class="col-3">
                <div
                  class="roundedContainer d-flex flex-column align-items-center justify-content-center cursor-pointer py-2"
                  :class="{
                    selected: block.productsPerPage == 3,
                  }"
                  @click="block.productsPerPage = 3"
                >
                  <span
                    class="sidebarElementSubTitle"
                    :style="
                      block.productsPerPage == 3
                        ? 'color:#4d1b7e;font-weight:500;'
                        : null
                    "
                    >3</span
                  >
                </div>
              </div>
              <div class="col-3">
                <div
                  class="roundedContainer d-flex flex-column align-items-center justify-content-center cursor-pointer py-2"
                  :class="{
                    selected: block.productsPerPage == 4,
                  }"
                  @click="block.productsPerPage = 4"
                >
                  <span
                    class="sidebarElementSubTitle"
                    :style="
                      block.productsPerPage == 4
                        ? 'color:#4d1b7e;font-weight:500;'
                        : null
                    "
                    >4</span
                  >
                </div>
              </div>
            </div>
            <div class="my-2 row" v-else>
              <div class="col-3">
                <div
                  class="roundedContainer d-flex flex-column align-items-center justify-content-center cursor-pointer py-2"
                  :class="{
                    selected: block.productsPerPageMobile == 1,
                  }"
                  @click="block.productsPerPageMobile = 1"
                >
                  <span
                    class="sidebarElementSubTitle"
                    :style="
                      block.productsPerPageMobile == 1
                        ? 'color:#4d1b7e;font-weight:500;'
                        : null
                    "
                    >1</span
                  >
                </div>
              </div>
              <div class="col-3">
                <div
                  class="roundedContainer d-flex flex-column align-items-center justify-content-center cursor-pointer py-2"
                  :class="{
                    selected: block.productsPerPageMobile == 2,
                  }"
                  @click="block.productsPerPageMobile = 2"
                >
                  <span
                    class="sidebarElementSubTitle"
                    :style="
                      block.productsPerPageMobile == 2
                        ? 'color:#4d1b7e;font-weight:500;'
                        : null
                    "
                    >2</span
                  >
                </div>
              </div>
            </div>

            <div class="my-2">
              <span
                class="sidebarElementSubTitle"
                style="font-size: 14px; font-weight: 500"
              >
                {{ isDesktop ? "Rows :" : "Rows (mobile) :" }}</span
              >

              <div class="mt-2">
                <div
                  class="form-group d-flex align-items-center"
                  v-if="isDesktop"
                >
                  <input
                    type="range"
                    class="form-control-range formControlRange"
                    id="RowSlider"
                    min="1"
                    max="10"
                    :key="count + 'row'"
                    v-model="block.rows"
                  />

                  <span
                    class="sidebarElementSubTitle ml-3"
                    style="font-size: 14px; font-weight: 500"
                    >{{ block.rows }}</span
                  >
                </div>
                <div class="form-group d-flex align-items-center" v-else>
                  <input
                    type="range"
                    class="form-control-range formControlRange"
                    id="RowSlider"
                    min="1"
                    max="10"
                    :key="count + 'mobileRows'"
                    v-model="block.mobileRows"
                  />

                  <span
                    class="sidebarElementSubTitle ml-3"
                    style="font-size: 14px; font-weight: 500"
                    >{{ block.mobileRows }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Display Setting Accordion End -->

      <!--Image Setting Accordion Start -->

      <div class="my-2 pb-3" style="border-bottom: 1px solid #18191c14">
        <div
          class="d-flex align-items-center justify-content-between cursor-pointer"
          @click="updateSelectedBlock('imageSettings')"
        >
          <span class="sidebarElementTitle">Image Settings</span>
          <div>
            <svg
              v-if="selectedBlock == 'imageSettings'"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.8167 12.8417L10 9.025L6.175 12.8417L5 11.6667L10 6.66667L15 11.6667L13.8167 12.8417Z"
                fill="#73738D"
              />
            </svg>
            <svg
              v-else
              style="transform: rotate(180deg)"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.8167 12.8417L10 9.025L6.175 12.8417L5 11.6667L10 6.66667L15 11.6667L13.8167 12.8417Z"
                fill="#73738D"
              />
            </svg>
          </div>
        </div>
        <div class="my-2" v-if="selectedBlock == 'imageSettings'">
          <div class="mt-1">
            <span
              class="sidebarElementSubTitle"
              style="font-size: 14px; font-weight: 500"
              >Ratio</span
            >
          </div>

          <div class="my-2 row">
            <div class="col-4">
              <div
                class="roundedContainer d-flex flex-column align-items-center justify-content-center cursor-pointer py-2"
                :class="{
                  selected: block.style.imageRatio === 'square',
                }"
                @click="block.style.imageRatio = 'square'"
              >
                <svg
                  class="imageRatioSVG"
                  :class="{
                    selected: block.style.imageRatio === 'square',
                  }"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 2.5H18V17.5H3V2.5ZM4.66667 4.16667V15.8333H16.3333V4.16667H4.66667Z"
                    fill="#73738D"
                  />
                </svg>

                <span
                  class="sidebarElementSubTitle"
                  :style="
                    block.style.imageRatio === 'square'
                      ? 'color:#4d1b7e;font-weight:500;'
                      : null
                  "
                  >1:1</span
                >
              </div>
            </div>
            <div class="col-4">
              <div
                class="roundedContainer d-flex flex-column align-items-center justify-content-center cursor-pointer py-2"
                :class="{
                  selected: block.style.imageRatio === 'potrait',
                }"
                @click="block.style.imageRatio = 'potrait'"
              >
                <svg
                  class="imageRatioSVG"
                  :class="{
                    selected: block.style.imageRatio === 'potrait',
                  }"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.5 2.49999V17.5H5.50004V2.49999H15.5ZM17.1667 0.833328H3.83337V19.1667H17.1667V0.833328Z"
                    fill="#73738D"
                  />
                </svg>

                <span
                  class="sidebarElementSubTitle"
                  :style="
                    block.style.imageRatio === 'potrait'
                      ? 'color:#4d1b7e;font-weight:500;'
                      : null
                  "
                  >2:3</span
                >
              </div>
            </div>
            <div class="col-4">
              <div
                class="roundedContainer d-flex flex-column align-items-center justify-content-center cursor-pointer py-2"
                :class="{
                  selected: block.style.imageRatio === 'landscape',
                }"
                @click="block.style.imageRatio = 'landscape'"
              >
                <svg
                  class="imageRatioSVG"
                  :class="{
                    selected: block.style.imageRatio === 'landscape',
                  }"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 15L2.99996 15L2.99996 4.99999L18 4.99999L18 15ZM19.6666 16.6667L19.6666 3.33333L1.33329 3.33333L1.33329 16.6667L19.6666 16.6667Z"
                    fill="#73738D"
                  />
                </svg>

                <span
                  class="sidebarElementSubTitle"
                  :style="
                    block.style.imageRatio === 'landscape'
                      ? 'color:#4d1b7e;font-weight:500;'
                      : null
                  "
                  >3:2</span
                >
              </div>
            </div>
          </div>

          <div class="mt-2">
            <span
              class="sidebarElementSubTitle"
              style="font-size: 14px; font-weight: 500"
              >Scale</span
            >
          </div>

          <div class="my-2 row">
            <div class="col-6">
              <div
                class="roundedContainer d-flex align-items-center justify-content-center cursor-pointer py-2"
                @click="block.style.imageFit = 'Fit'"
                :class="{
                  selected: block.style.imageFit === 'Fit',
                }"
              >
                <span
                  class="sidebarElementSubTitle"
                  :style="
                    block.style.imageFit === 'Fit'
                      ? 'color:#4d1b7e;font-weight:500;'
                      : null
                  "
                  >Fit</span
                >
              </div>
            </div>
            <div class="col-6">
              <div
                class="roundedContainer d-flex align-items-center justify-content-center cursor-pointer py-2"
                @click="block.style.imageFit = 'Fill'"
                :class="{
                  selected: block.style.imageFit === 'Fill',
                }"
              >
                <span
                  class="sidebarElementSubTitle"
                  :style="
                    block.style.imageFit === 'Fill'
                      ? 'color:#4d1b7e;font-weight:500;'
                      : null
                  "
                  >Fill</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Image Setting Accordion End -->
      <!--Block Style Accordion Start -->

      <div class="my-2 pb-3" style="border-bottom: 1px solid #18191c14">
        <div
          class="d-flex align-items-center justify-content-between cursor-pointer"
          @click="updateSelectedBlock('blockSettings')"
        >
          <span class="sidebarElementTitle">Block Settings</span>
          <div>
            <svg
              v-if="selectedBlock == 'blockSettings'"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.8167 12.8417L10 9.025L6.175 12.8417L5 11.6667L10 6.66667L15 11.6667L13.8167 12.8417Z"
                fill="#73738D"
              />
            </svg>
            <svg
              v-else
              style="transform: rotate(180deg)"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.8167 12.8417L10 9.025L6.175 12.8417L5 11.6667L10 6.66667L15 11.6667L13.8167 12.8417Z"
                fill="#73738D"
              />
            </svg>
          </div>
        </div>
        <div class="my-2" v-if="selectedBlock == 'blockSettings'">
          <div class="mt-1">
            <span
              class="sidebarElementSubTitle"
              style="font-size: 14px; font-weight: 500"
              >Border</span
            >

            <div class="d-flex align-items-center justify-content-between mt-2">
              <div class="d-flex align-items-center">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.8333 13.8333H15.5V15.5H13.8333V13.8333ZM13.8333 12.1667H15.5V10.5H13.8333V12.1667ZM0.5 8.83333H2.16667V7.16667H0.5V8.83333ZM0.5 12.1667H2.16667V10.5H0.5V12.1667ZM0.5 5.5H2.16667V3.83333H0.5V5.5ZM0.5 2.16667H2.16667V0.5H0.5V2.16667ZM3.83333 2.16667H5.5V0.5H3.83333V2.16667ZM10.5 15.5H12.1667V13.8333H10.5V15.5ZM7.16667 15.5H8.83333V13.8333H7.16667V15.5ZM10.5 15.5H12.1667V13.8333H10.5V15.5ZM3.83333 15.5H5.5V13.8333H3.83333V15.5ZM0.5 15.5H2.16667V13.8333H0.5V15.5ZM15.5 4.66667C15.5 2.36667 13.6333 0.5 11.3333 0.5H7.16667V2.16667H11.3333C12.7083 2.16667 13.8333 3.29167 13.8333 4.66667V8.83333H15.5V4.66667Z"
                    fill="#B3AFB6"
                  />
                </svg>

                <input
                  type="number"
                  min="0"
                  max="50"
                  v-model="block.style.borderRadius"
                  class="form-control-sm px-2 ml-2 input-border-style"
                  style="width: 50px; max-width: 50px"
                />
              </div>
              <div class="d-flex align-items-center">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667ZM13 13.8333H3C2.54167 13.8333 2.16667 13.4583 2.16667 13V3C2.16667 2.54167 2.54167 2.16667 3 2.16667H13C13.4583 2.16667 13.8333 2.54167 13.8333 3V13C13.8333 13.4583 13.4583 13.8333 13 13.8333Z"
                    fill="#B3AFB6"
                  />
                </svg>

                <input
                  type="number"
                  v-model="block.style.borderSize"
                  min="0"
                  class="form-control-sm px-2 ml-2 input-border-style"
                  style="width: 50px; max-width: 50px"
                />
              </div>
            </div>
            <div class="d-flex align-items-center mt-2 ml-n2">
              <input
                type="color"
                v-model="block.style.borderColor"
                class="colorInput"
                v-b-tooltip.hover
                title="Button Border Color"
              />
              <input
                type="text"
                class="form-control-sm px-2 input-border-style"
                style="max-width: 80px"
                v-model="block.style.borderColor"
              />
            </div>
          </div>

          <div class="my-2">
            <span
              class="sidebarElementSubTitle"
              style="font-size: 14px; font-weight: 500"
              >Backgorund Color</span
            >

            <div class="d-flex align-items-center mt-2">
              <input
                type="color"
                v-model="block.style.backgroundColor"
                class="colorInput"
              />
              <input
                type="text"
                class="form-control-sm px-2 ml-2 input-border-style"
                style="max-width: 80px"
                v-model="block.style.backgroundColor"
              />
            </div>
          </div>
          <div class="my-2 row">
            <div class="col-12 mb-2">
              <span
                class="sidebarElementSubTitle"
                style="font-size: 14px; font-weight: 500"
                >Currency</span
              >
            </div>

            <div class="col-6">
              <div
                class="roundedContainer d-flex align-items-center justify-content-center cursor-pointer py-2"
                @click="block.style.Currencyposition = 'left'"
                :class="{
                  selected: block.style.Currencyposition === 'left',
                }"
              >
                <span
                  class="sidebarElementSubTitle"
                  :style="
                    block.style.Currencyposition === 'left'
                      ? 'color:#4d1b7e;font-weight:500;'
                      : null
                  "
                  >Left</span
                >
              </div>
            </div>
            <div class="col-6">
              <div
                class="roundedContainer d-flex align-items-center justify-content-center cursor-pointer py-2"
                @click="block.style.Currencyposition = 'right'"
                :class="{
                  selected: block.style.Currencyposition === 'right',
                }"
              >
                <span
                  class="sidebarElementSubTitle"
                  :style="
                    block.style.Currencyposition === 'right'
                      ? 'color:#4d1b7e;font-weight:500;'
                      : null
                  "
                  >Right</span
                >
              </div>
            </div>
          </div>
          <div class="my-2">
            <span
              class="sidebarElementSubTitle"
              style="font-size: 14px; font-weight: 500"
              >Padding ({{ isDesktop ? "Desktop" : "Mobile" }})</span
            >

            <div class="d-flex align-items-center justify-content-between my-2">
              <div class="d-flex align-items-center">
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z"
                    fill="#B3AFB6"
                  />
                </svg>

                <input
                  v-if="isDesktop"
                  type="number"
                  min="0"
                  max="50"
                  v-model="block.style.pagePaddingTop"
                  class="form-control-sm px-2 ml-2 input-border-style"
                  style="width: 50px; max-width: 50px"
                />
                <input
                  v-else
                  type="number"
                  min="0"
                  max="50"
                  v-model="block.style.pageMobilePaddingTop"
                  class="form-control-sm px-2 ml-2 input-border-style"
                  style="width: 50px; max-width: 50px"
                />
              </div>
              <div class="d-flex align-items-center">
                <svg
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z"
                    fill="#B3AFB6"
                  />
                </svg>

                <input
                  v-if="isDesktop"
                  type="number"
                  min="0"
                  max="50"
                  v-model="block.style.pagePaddingBottom"
                  class="form-control-sm px-2 ml-2 input-border-style"
                  style="width: 50px; max-width: 50px"
                />
                <input
                  v-else
                  type="number"
                  min="0"
                  max="50"
                  v-model="block.style.pageMobilePaddingBottom"
                  class="form-control-sm px-2 ml-2 input-border-style"
                  style="width: 50px; max-width: 50px"
                />
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between my-2">
              <div class="d-flex align-items-center">
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z"
                    fill="#B3AFB6"
                  />
                </svg>

                <input
                  v-if="isDesktop"
                  type="number"
                  min="0"
                  max="50"
                  v-model="block.style.pagePaddingLeft"
                  class="form-control-sm px-2 ml-2 input-border-style"
                  style="width: 50px; max-width: 50px"
                />
                <input
                  v-else
                  type="number"
                  min="0"
                  max="50"
                  v-model="block.style.pageMobilePaddingLeft"
                  class="form-control-sm px-2 ml-2 input-border-style"
                  style="width: 50px; max-width: 50px"
                />
              </div>
              <div class="d-flex align-items-center">
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z"
                    fill="#B3AFB6"
                  />
                </svg>

                <input
                  v-if="isDesktop"
                  type="number"
                  min="0"
                  max="50"
                  v-model="block.style.pagePaddingRight"
                  class="form-control-sm px-2 ml-2 input-border-style"
                  style="width: 50px; max-width: 50px"
                />
                <input
                  v-else
                  type="number"
                  min="0"
                  max="50"
                  v-model="block.style.pageMobilePaddingRight"
                  class="form-control-sm px-2 ml-2 input-border-style"
                  style="width: 50px; max-width: 50px"
                />
              </div>
            </div>
          </div>
          <div class="mb-2 mt-3">
            <span
              class="sidebarElementSubTitle"
              style="font-size: 14px; font-weight: 500"
              >Other Options</span
            >
            <div
              class="px-2"
              style="
                border-radius: 8px;
                border: 1px solid rgba(24, 25, 28, 0.08);
              "
            >
              <div class="pb-3 mt-2" style="border-bottom: 1px solid #18191c14">
                <div class="d-flex align-items-center justify-content-between">
                  <span
                    class="sidebarElementTitle"
                    style="font-size: 14px; font-weight: 500"
                    >Hide Price</span
                  >

                  <div
                    class="show-logo custom-control custom-switch cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="`hidePRice-${Date.now()}`"
                      @change="updateHidePriceFunc()"
                      v-model="hidePrice"
                    />

                    <label
                      class="custom-control-label"
                      :for="`hidePRice-${Date.now()}`"
                    ></label>
                  </div>
                </div>
              </div>
              <div class="pb-3 mt-2" style="border-bottom: 1px solid #18191c14">
                <div class="d-flex align-items-center justify-content-between">
                  <span
                    class="sidebarElementTitle"
                    style="font-size: 14px; font-weight: 500"
                    >Hide Description</span
                  >

                  <div
                    class="show-logo custom-control custom-switch cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="`hideDescription-${Date.now()}`"
                      v-model="block.hideDescription"
                    />
                    <label
                      class="custom-control-label"
                      :for="`hideDescription-${Date.now()}`"
                    ></label>
                  </div>
                </div>
              </div>
              <div class="pb-3 mt-2" style="border-bottom: 1px solid #18191c14">
                <div class="d-flex align-items-center justify-content-between">
                  <span
                    class="sidebarElementTitle"
                    style="font-size: 14px; font-weight: 500"
                    >Limit Description</span
                  >

                  <div
                    class="show-logo custom-control custom-switch cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="`limitDEscription-${Date.now()}`"
                      v-model="block.limitDescription"
                    />

                    <label
                      class="custom-control-label"
                      :for="`limitDEscription-${Date.now()}`"
                    ></label>
                  </div>
                </div>
              </div>
              <div
                class="pb-3 mt-2"
                style="border-bottom: 1px solid #18191c14"
                v-if="block.viewType == 'detailList' && !block.hideDescription"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <span
                    class="sidebarElementTitle"
                    style="font-size: 14px; font-weight: 500"
                    >Hide Description (Heirarchy)</span
                  >

                  <div
                    class="show-logo custom-control custom-switch cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="`showHeirarchyDesciption-${Date.now()}`"
                      v-model="block.showHeirarchyDesciption"
                    />

                    <label
                      class="custom-control-label"
                      :for="`showHeirarchyDesciption-${Date.now()}`"
                    ></label>
                  </div>
                </div>
              </div>
              <div class="pb-3 mt-2" style="border-bottom: 1px solid #18191c14">
                <div class="d-flex align-items-center justify-content-between">
                  <span
                    class="sidebarElementTitle"
                    style="font-size: 14px; font-weight: 500"
                    >Use Bullet Description</span
                  >

                  <div
                    class="show-logo custom-control custom-switch cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="`bulletDescription-${Date.now()}`"
                      v-model="block.useBulletDescription"
                    />

                    <label
                      class="custom-control-label"
                      :for="`bulletDescription-${Date.now()}`"
                    ></label>
                  </div>
                </div>

                <div v-if="block.useBulletDescription">
                  <div class="mt-2">
                    <span
                      class="sidebarElementSubTitle"
                      style="font-size: 14px; font-weight: 500"
                      >Bullet Description</span
                    >

                    <div
                      class="d-flex align-items-center justify-content-between my-2"
                    >
                      <div class="d-flex align-items-center">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          title="Font"
                        >
                          <path
                            d="M2.08333 3.33334V5.83334H6.24999V15.8333H8.74999V5.83334H12.9167V3.33334H2.08333ZM17.9167 7.50001H10.4167V10H12.9167V15.8333H15.4167V10H17.9167V7.50001Z"
                            fill="#B3AFB6"
                          />
                        </svg>
                        <select
                          class="w-100 newSelectInput ml-2"
                          aria-label="Default select example"
                          v-model="block.style.bulletDescriptionfontFamily"
                        >
                          <option
                            v-for="font in getFontFamilyList"
                            :key="font.value"
                            :value="font.value"
                          >
                            {{ font.value }}
                          </option>
                        </select>
                      </div>
                      <div class="d-flex align-items-center">
                        <span
                          :class="[
                            {
                              'active-svg':
                                block.style.bulletDescriptiontextAlign ===
                                'left',
                            },
                            'font-style-button',
                            'mx-1 px-2',
                          ]"
                          type="button"
                          @click="
                            block.style.bulletDescriptiontextAlign = 'left'
                          "
                        >
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.22223 9.2222H0.333344V10.7037H9.22223V9.2222ZM9.22223 3.29628H0.333344V4.77776H9.22223V3.29628ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                              fill="#B3AFB6"
                            />
                          </svg>
                        </span>
                        <span
                          :class="[
                            {
                              'active-svg':
                                block.style.bulletDescriptiontextAlign ===
                                'center',
                            },
                            'font-style-button',
                            'mx-1 px-2',
                          ]"
                          type="button"
                          @click="
                            block.style.bulletDescriptiontextAlign = 'center'
                          "
                        >
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.29631 9.2222V10.7037H10.7037V9.2222H3.29631ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM3.29631 3.29628V4.77776H10.7037V3.29628H3.29631ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                              fill="#B3AFB6"
                            />
                          </svg>
                        </span>
                        <span
                          :class="[
                            {
                              'active-svg':
                                block.style.bulletDescriptiontextAlign ===
                                'end',
                            },
                            'font-style-button',
                            'mx-1 px-2',
                          ]"
                          type="button"
                          @click="
                            block.style.bulletDescriptiontextAlign = 'end'
                          "
                        >
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.166656 11.8334H11.8333V10.5371H0.166656V11.8334ZM4.05555 9.24076H11.8333V7.94447H4.05555V9.24076ZM0.166656 6.64817H11.8333V5.35187H0.166656V6.64817ZM4.05555 4.05558H11.8333V2.75928H4.05555V4.05558ZM0.166656 0.166687V1.46298H11.8333V0.166687H0.166656Z"
                              fill="#B3AFB6"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>

                    <div
                      class="d-flex align-items-center justify-content-between my-2"
                    >
                      <div class="d-flex align-items-center">
                        <svg
                          width="19"
                          height="13"
                          viewBox="0 0 19 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z"
                            fill="#B3AFB6"
                          />
                          <path
                            d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z"
                            fill="#B3AFB6"
                          />
                        </svg>

                        <input
                          type="number"
                          min="0"
                          v-model="block.style.bulletDescriptionfontSize"
                          class="form-control-sm px-2 ml-2 input-border-style"
                          style="width: 50px; max-width: 50px"
                        />
                      </div>
                      <div class="d-flex align-items-center">
                        <span
                          title="Bold"
                          class="mr-1 cursorPointer font-style-button"
                          :class="{
                            'active-svg':
                              block.style.bulletDescriptionfontWeight ===
                              'bold',
                          }"
                          @click="
                            block.style.bulletDescriptionfontWeight =
                              block.style.bulletDescriptionfontWeight ==
                              'normal'
                                ? 'bold'
                                : 'normal'
                          "
                        >
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 10 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.00001 5.99165C8.80834 5.43331 9.37501 4.51665 9.37501 3.66665C9.37501 1.78331 7.91668 0.333313 6.04168 0.333313H0.833344V12H6.70001C8.44168 12 9.79168 10.5833 9.79168 8.84165C9.79168 7.57498 9.07501 6.49165 8.00001 5.99165ZM3.33334 2.41665H5.83334C6.52501 2.41665 7.08334 2.97498 7.08334 3.66665C7.08334 4.35831 6.52501 4.91665 5.83334 4.91665H3.33334V2.41665ZM6.25001 9.91665H3.33334V7.41665H6.25001C6.94168 7.41665 7.50001 7.97498 7.50001 8.66665C7.50001 9.35831 6.94168 9.91665 6.25001 9.91665Z"
                              fill="#B3AFB6"
                            />
                          </svg>
                        </span>
                        <span
                          title="Italic"
                          class="mx-1 cursorPointer font-style-button"
                          :class="{
                            'active-svg':
                              block.style.bulletDescriptionfontStyle ===
                              'italic',
                          }"
                          @click="
                            block.style.bulletDescriptionfontStyle =
                              block.style.bulletDescriptionfontStyle == 'normal'
                                ? 'italic'
                                : 'normal'
                          "
                        >
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 10 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.33333 0.333313V2.83331H5.175L2.325 9.49998H0V12H6.66667V9.49998H4.825L7.675 2.83331H10V0.333313H3.33333Z"
                              fill="#B3AFB6"
                            />
                          </svg>
                        </span>
                        <span
                          title="Underline"
                          class="mx-1 cursorPointer font-style-button"
                          :class="{
                            'active-svg':
                              block.style.bulletDescriptiontextDecoration ===
                              'underline',
                          }"
                          @click="
                            block.style.bulletDescriptiontextDecoration =
                              block.style.bulletDescriptiontextDecoration ==
                              'underline'
                                ? 'unset'
                                : 'underline'
                          "
                        >
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 12 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.99999 12.1667C8.75832 12.1667 11 9.925 11 7.16667V0.5H8.91666V7.16667C8.91666 8.775 7.60832 10.0833 5.99999 10.0833C4.39166 10.0833 3.08332 8.775 3.08332 7.16667V0.5H0.99999V7.16667C0.99999 9.925 3.24166 12.1667 5.99999 12.1667ZM0.166656 13.8333V15.5H11.8333V13.8333H0.166656Z"
                              fill="#B3AFB6"
                            />
                          </svg>
                        </span>
                      </div>
                    </div>

                    <div class="my-2">
                      <span
                        class="sidebarElementSubTitle"
                        style="font-size: 14px; font-weight: 500"
                        >Color</span
                      >

                      <div
                        class="d-flex align-items-center justify-content-between my-2"
                      >
                        <div class="d-flex align-items-center">
                          <input
                            type="color"
                            v-model="block.style.bulletDescriptiontitleColor"
                            class="colorInput"
                          />
                          <input
                            type="text"
                            class="form-control-sm px-2 ml-2 input-border-style"
                            style="max-width: 80px"
                            v-model="block.style.bulletDescriptiontitleColor"
                          />
                        </div>

                        <div class="d-flex align-items-center">
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 14 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
                              fill="#B3AFB6"
                            />
                          </svg>

                          <input
                            type="number"
                            min="0"
                            v-model="
                              block.style.bulletDescriptiontitleTextOpacity
                            "
                            style="max-width: 80px"
                            class="form-control-sm px-2 ml-2 input-border-style"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="my-2">
                      <span
                        class="sidebarElementSubTitle"
                        style="font-size: 14px; font-weight: 500"
                        >Bullet Icon</span
                      >

                      <select
                        class="w-100 newSelectInput"
                        aria-label="Default select example"
                        v-model="block.selectedBulletIcon"
                      >
                        <option value="fa-check">Check</option>
                        <option value="fa-check-circle">Check Cirlce</option>
                        <option value="fa-check-square">Check Square</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pb-3 mt-2" style="border-bottom: 1px solid #18191c14">
                <div class="d-flex align-items-center justify-content-between">
                  <span
                    class="sidebarElementTitle"
                    style="font-size: 14px; font-weight: 500"
                    >Product Rank</span
                  >

                  <div
                    class="show-logo custom-control custom-switch cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="`showProductRank-${Date.now()}`"
                      v-model="block.showProductRank"
                    />

                    <label
                      class="custom-control-label"
                      :for="`showProductRank-${Date.now()}`"
                    ></label>
                  </div>
                </div>

                <div v-if="block.showProductRank">
                  <div class="mt-2">
                    <span
                      class="sidebarElementSubTitle"
                      style="font-size: 14px; font-weight: 500"
                      >Add/Edit Product Ranks</span
                    >

                    <div
                      v-for="(rankOption, index) in rankOptionArr"
                      :key="index"
                    >
                      <div
                        class="form-group d-flex mb-2 align-items-center row"
                      >
                        <div class="col-10">
                          <div>
                            <input
                              type="text"
                              class="form-control-sm px-2 w-100 input-border-style"
                              v-model="rankOption.text"
                              placeholder="Product Rank"
                            />
                          </div>
                        </div>
                        <div class="col-2" v-if="rankOptionArr.length > 2">
                          <button
                            class="btn p-0"
                            @click.stop="deleteRankOption(index)"
                          >
                            <svg
                              width="28"
                              height="28"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
                                fill="transparent"
                                fill-opacity="0.5"
                              />
                              <path
                                d="M10.2856 23.2593C10.2856 24.4 11.1428 25.3333 12.1904 25.3333H19.8094C20.8571 25.3333 21.7142 24.4 21.7142 23.2593V10.8148H10.2856V23.2593ZM12.1904 12.8889H19.8094V23.2593H12.1904V12.8889ZM19.3333 7.70369L18.3809 6.66666H13.619L12.6666 7.70369H9.33325V9.77777H22.6666V7.70369H19.3333Z"
                                fill="#b3afb6"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="my-2">
                      <Button
                        @click="addRankOption()"
                        class="w-100 text-center"
                      >
                        <svg
                          width="12"
                          height="12"
                          class="mr-2"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.8332 6.83334H6.83317V11.8333H5.1665V6.83334H0.166504V5.16667H5.1665V0.166672H6.83317V5.16667H11.8332V6.83334Z"
                            fill="#ffffff"
                          />
                        </svg>

                        <span class="">Add Product Rank</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="pb-3 mt-2"
                style="border-bottom: 1px solid #18191c14"
                v-if="this.getUserType == 'shopify'"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <span
                    class="sidebarElementTitle"
                    style="font-size: 14px; font-weight: 500"
                    >Show product variants</span
                  >
                  <div
                    class="show-logo custom-control custom-switch cursor-pointer"
                    @click="openUpgradePlansModalForAddToCart('variant')"
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="`productVariant-${Date.now()}`"
                      :disabled="!showAddToCartAndVariant"
                      v-model="block.useProductVariant"
                    />

                    <label
                      class="custom-control-label"
                      :for="`productVariant-${Date.now()}`"
                    ></label>
                  </div>
                </div>

                <div v-if="block.useProductVariant">
                  <div class="mt-2">
                    <span
                      class="sidebarElementSubTitle"
                      style="font-size: 14px; font-weight: 500"
                      >Text</span
                    >

                    <div class="d-flex align-items-center mt-2">
                      <input
                        type="text"
                        v-model="block.selectVariantText"
                        class="form-control-sm w-100 px-2 input-border-style"
                      />
                    </div>

                    <div
                      class="d-flex align-items-center justify-content-between my-2"
                    >
                      <div class="d-flex align-items-center">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          title="Font"
                        >
                          <path
                            d="M2.08333 3.33334V5.83334H6.24999V15.8333H8.74999V5.83334H12.9167V3.33334H2.08333ZM17.9167 7.50001H10.4167V10H12.9167V15.8333H15.4167V10H17.9167V7.50001Z"
                            fill="#B3AFB6"
                          />
                        </svg>
                        <select
                          class="w-100 newSelectInput ml-2"
                          aria-label="Default select example"
                          v-model="block.style.productVariantStyles.fontFamily"
                        >
                          <option
                            v-for="font in getFontFamilyList"
                            :key="font.value"
                            :value="font.value"
                          >
                            {{ font.value }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div
                      class="d-flex align-items-center justify-content-between my-2"
                    >
                      <div class="d-flex align-items-center">
                        <svg
                          width="19"
                          height="13"
                          viewBox="0 0 19 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z"
                            fill="#B3AFB6"
                          />
                          <path
                            d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z"
                            fill="#B3AFB6"
                          />
                        </svg>

                        <input
                          type="number"
                          min="0"
                          v-model="block.style.productVariantStyles.fontSize"
                          class="form-control-sm px-2 ml-2 input-border-style"
                          style="width: 50px; max-width: 50px"
                        />
                      </div>
                    </div>

                    <div class="my-2">
                      <span
                        class="sidebarElementSubTitle"
                        style="font-size: 14px; font-weight: 500"
                        >Background</span
                      >

                      <div
                        class="d-flex align-items-center justify-content-between my-2"
                      >
                        <div class="d-flex align-items-center">
                          <input
                            type="color"
                            v-model="
                              block.style.productVariantStyles.background
                            "
                            class="colorInput"
                          />
                          <input
                            type="text"
                            class="form-control-sm px-2 ml-2 input-border-style"
                            style="max-width: 80px"
                            v-model="
                              block.style.productVariantStyles.background
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div class="my-2">
                      <span
                        class="sidebarElementSubTitle"
                        style="font-size: 14px; font-weight: 500"
                        >Text Color</span
                      >

                      <div
                        class="d-flex align-items-center justify-content-between my-2"
                      >
                        <div class="d-flex align-items-center">
                          <input
                            type="color"
                            v-model="block.style.productVariantStyles.color"
                            class="colorInput"
                          />
                          <input
                            type="text"
                            class="form-control-sm px-2 ml-2 input-border-style"
                            style="max-width: 80px"
                            v-model="block.style.productVariantStyles.color"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="my-2">
                      <span
                        class="sidebarElementSubTitle"
                        style="font-size: 14px; font-weight: 500"
                        >Arrow Background</span
                      >

                      <div
                        class="d-flex align-items-center justify-content-between my-2"
                      >
                        <div class="d-flex align-items-center">
                          <input
                            type="color"
                            v-model="
                              block.style.productVariantStyles.arrowBackground
                            "
                            class="colorInput"
                          />
                          <input
                            type="text"
                            class="form-control-sm px-2 ml-2 input-border-style"
                            style="max-width: 80px"
                            v-model="
                              block.style.productVariantStyles.arrowBackground
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div class="my-2">
                      <span
                        class="sidebarElementSubTitle"
                        style="font-size: 14px; font-weight: 500"
                        >Arrow Color</span
                      >

                      <div
                        class="d-flex align-items-center justify-content-between my-2"
                      >
                        <div class="d-flex align-items-center">
                          <input
                            type="color"
                            v-model="
                              block.style.productVariantStyles.arrowColor
                            "
                            class="colorInput"
                          />
                          <input
                            type="text"
                            class="form-control-sm px-2 ml-2 input-border-style"
                            style="max-width: 80px"
                            v-model="
                              block.style.productVariantStyles.arrowColor
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Block Style Accordion End -->
      <!--Text Style Accordion Start -->

      <div class="my-2 pb-3" style="border-bottom: 1px solid #18191c14">
        <div
          class="d-flex align-items-center justify-content-between cursor-pointer"
          @click="updateSelectedBlock('textSettings')"
        >
          <span class="sidebarElementTitle">Text Settings</span>
          <div>
            <svg
              v-if="selectedBlock == 'textSettings'"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.8167 12.8417L10 9.025L6.175 12.8417L5 11.6667L10 6.66667L15 11.6667L13.8167 12.8417Z"
                fill="#73738D"
              />
            </svg>
            <svg
              v-else
              style="transform: rotate(180deg)"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.8167 12.8417L10 9.025L6.175 12.8417L5 11.6667L10 6.66667L15 11.6667L13.8167 12.8417Z"
                fill="#73738D"
              />
            </svg>
          </div>
        </div>
        <div class="my-2" v-if="selectedBlock == 'textSettings'">
          <div class="mt-1">
            <span
              class="sidebarElementSubTitle"
              style="font-size: 14px; font-weight: 500"
              >Product Title</span
            >

            <div class="d-flex align-items-center justify-content-between my-2">
              <div class="d-flex align-items-center">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  title="Font"
                >
                  <path
                    d="M2.08333 3.33334V5.83334H6.24999V15.8333H8.74999V5.83334H12.9167V3.33334H2.08333ZM17.9167 7.50001H10.4167V10H12.9167V15.8333H15.4167V10H17.9167V7.50001Z"
                    fill="#B3AFB6"
                  />
                </svg>

                <select
                  class="w-100 newSelectInput ml-2"
                  aria-label="Default select example"
                  v-model="block.style.fontFamily"
                >
                  <option
                    v-for="font in getFontFamilyList"
                    :key="font.value"
                    :value="font.value"
                  >
                    {{ font.value }}
                  </option>
                </select>
              </div>
              <div class="d-flex align-items-center">
                <span
                  :class="[
                    { 'active-svg': block.style.textAlign === 'left' },
                    'font-style-button',
                    'mx-1 px-2',
                  ]"
                  type="button"
                  @click="block.style.textAlign = 'left'"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.22223 9.2222H0.333344V10.7037H9.22223V9.2222ZM9.22223 3.29628H0.333344V4.77776H9.22223V3.29628ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
                <span
                  :class="[
                    { 'active-svg': block.style.textAlign === 'center' },
                    'font-style-button',
                    'mx-1 px-2',
                  ]"
                  type="button"
                  @click="block.style.textAlign = 'center'"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.29631 9.2222V10.7037H10.7037V9.2222H3.29631ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM3.29631 3.29628V4.77776H10.7037V3.29628H3.29631ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
                <span
                  :class="[
                    { 'active-svg': block.style.textAlign === 'end' },
                    'font-style-button',
                    'mx-1 px-2',
                  ]"
                  type="button"
                  @click="block.style.textAlign = 'end'"
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.166656 11.8334H11.8333V10.5371H0.166656V11.8334ZM4.05555 9.24076H11.8333V7.94447H4.05555V9.24076ZM0.166656 6.64817H11.8333V5.35187H0.166656V6.64817ZM4.05555 4.05558H11.8333V2.75928H4.05555V4.05558ZM0.166656 0.166687V1.46298H11.8333V0.166687H0.166656Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div class="d-flex align-items-center justify-content-between my-2">
              <div class="d-flex align-items-center">
                <svg
                  width="19"
                  height="13"
                  viewBox="0 0 19 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z"
                    fill="#B3AFB6"
                  />
                  <path
                    d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z"
                    fill="#B3AFB6"
                  />
                </svg>

                <input
                  type="number"
                  min="0"
                  v-model="block.style.fontSize"
                  class="form-control-sm px-2 ml-2 input-border-style"
                  style="width: 50px; max-width: 50px"
                />
              </div>
              <div class="d-flex align-items-center">
                <span
                  title="Bold"
                  class="mr-1 cursorPointer font-style-button"
                  :class="{
                    'active-svg': block.style.fontWeight === 'bold',
                  }"
                  @click="
                    block.style.fontWeight =
                      block.style.fontWeight == 'normal' ? 'bold' : 'normal'
                  "
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 10 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.00001 5.99165C8.80834 5.43331 9.37501 4.51665 9.37501 3.66665C9.37501 1.78331 7.91668 0.333313 6.04168 0.333313H0.833344V12H6.70001C8.44168 12 9.79168 10.5833 9.79168 8.84165C9.79168 7.57498 9.07501 6.49165 8.00001 5.99165ZM3.33334 2.41665H5.83334C6.52501 2.41665 7.08334 2.97498 7.08334 3.66665C7.08334 4.35831 6.52501 4.91665 5.83334 4.91665H3.33334V2.41665ZM6.25001 9.91665H3.33334V7.41665H6.25001C6.94168 7.41665 7.50001 7.97498 7.50001 8.66665C7.50001 9.35831 6.94168 9.91665 6.25001 9.91665Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
                <span
                  title="Italic"
                  class="mx-1 cursorPointer font-style-button"
                  :class="{
                    'active-svg': block.style.fontStyle === 'italic',
                  }"
                  @click="
                    block.style.fontStyle =
                      block.style.fontStyle == 'normal' ? 'italic' : 'normal'
                  "
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 10 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.33333 0.333313V2.83331H5.175L2.325 9.49998H0V12H6.66667V9.49998H4.825L7.675 2.83331H10V0.333313H3.33333Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
                <span
                  title="Underline"
                  class="mx-1 cursorPointer font-style-button"
                  :class="{
                    'active-svg': block.style.textDecoration === 'underline',
                  }"
                  @click="
                    block.style.textDecoration =
                      block.style.textDecoration == 'underline'
                        ? 'unset'
                        : 'underline'
                  "
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 12 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.99999 12.1667C8.75832 12.1667 11 9.925 11 7.16667V0.5H8.91666V7.16667C8.91666 8.775 7.60832 10.0833 5.99999 10.0833C4.39166 10.0833 3.08332 8.775 3.08332 7.16667V0.5H0.99999V7.16667C0.99999 9.925 3.24166 12.1667 5.99999 12.1667ZM0.166656 13.8333V15.5H11.8333V13.8333H0.166656Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div class="my-2">
              <span
                class="sidebarElementSubTitle"
                style="font-size: 14px; font-weight: 500"
                >Title Color</span
              >

              <div
                class="d-flex align-items-center justify-content-between my-2"
              >
                <div class="d-flex align-items-center">
                  <input
                    type="color"
                    v-model="block.style.titleColor"
                    class="colorInput"
                  />
                  <input
                    type="text"
                    class="form-control-sm px-2 ml-2 input-border-style"
                    style="max-width: 80px"
                    v-model="block.style.titleColor"
                  />
                </div>

                <div class="d-flex align-items-center">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 14 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
                      fill="#B3AFB6"
                    />
                  </svg>

                  <input
                    type="number"
                    min="0"
                    v-model="block.style.titleTextOpacity"
                    style="max-width: 80px"
                    class="form-control-sm px-2 ml-2 input-border-style"
                  />
                </div>
              </div>
            </div>

            <div class="my-2">
              <span
                class="sidebarElementSubTitle"
                style="font-size: 14px; font-weight: 500"
                >Price Color</span
              >

              <div class="d-flex align-items-center my-2">
                <input
                  type="color"
                  v-model="block.style.priceColor"
                  class="colorInput"
                />
                <input
                  type="text"
                  class="form-control-sm px-2 ml-2 input-border-style"
                  style="max-width: 80px"
                  v-model="block.style.priceColor"
                />
              </div>
            </div>

            <div class="my-2" v-if="block.viewType == 'detailList'">
              <span
                class="sidebarElementSubTitle"
                style="font-size: 14px; font-weight: 500"
                >More Option</span
              >

              <div
                class="d-flex align-items-center justify-content-between my-2"
              >
                <div class="d-flex align-items-center">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    title="Font"
                  >
                    <path
                      d="M2.08333 3.33334V5.83334H6.24999V15.8333H8.74999V5.83334H12.9167V3.33334H2.08333ZM17.9167 7.50001H10.4167V10H12.9167V15.8333H15.4167V10H17.9167V7.50001Z"
                      fill="#B3AFB6"
                    />
                  </svg>

                  <select
                    class="w-100 newSelectInput ml-2"
                    aria-label="Default select example"
                    v-model="block.style.detailListFontFamily"
                  >
                    <option
                      v-for="font in getFontFamilyList"
                      :key="font.value"
                      :value="font.value"
                    >
                      {{ font.value }}
                    </option>
                  </select>
                </div>
                <div class="d-flex align-items-center">
                  <span
                    :class="[
                      {
                        'active-svg':
                          block.style.detailListTextAlign === 'left',
                      },
                      'font-style-button',
                      'mx-1 px-2',
                    ]"
                    type="button"
                    @click="block.style.detailListTextAlign = 'left'"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.22223 9.2222H0.333344V10.7037H9.22223V9.2222ZM9.22223 3.29628H0.333344V4.77776H9.22223V3.29628ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                        fill="#B3AFB6"
                      />
                    </svg>
                  </span>
                  <span
                    :class="[
                      {
                        'active-svg':
                          block.style.detailListTextAlign === 'center',
                      },
                      'font-style-button',
                      'mx-1 px-2',
                    ]"
                    type="button"
                    @click="block.style.detailListTextAlign = 'center'"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.29631 9.2222V10.7037H10.7037V9.2222H3.29631ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM3.29631 3.29628V4.77776H10.7037V3.29628H3.29631ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                        fill="#B3AFB6"
                      />
                    </svg>
                  </span>
                  <span
                    :class="[
                      {
                        'active-svg': block.style.detailListTextAlign === 'end',
                      },
                      'font-style-button',
                      'mx-1 px-2',
                    ]"
                    type="button"
                    @click="block.style.detailListTextAlign = 'end'"
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.166656 11.8334H11.8333V10.5371H0.166656V11.8334ZM4.05555 9.24076H11.8333V7.94447H4.05555V9.24076ZM0.166656 6.64817H11.8333V5.35187H0.166656V6.64817ZM4.05555 4.05558H11.8333V2.75928H4.05555V4.05558ZM0.166656 0.166687V1.46298H11.8333V0.166687H0.166656Z"
                        fill="#B3AFB6"
                      />
                    </svg>
                  </span>
                </div>
              </div>

              <div
                class="d-flex align-items-center justify-content-between my-2"
              >
                <div class="d-flex align-items-center">
                  <svg
                    width="19"
                    height="13"
                    viewBox="0 0 19 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z"
                      fill="#B3AFB6"
                    />
                    <path
                      d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z"
                      fill="#B3AFB6"
                    />
                  </svg>

                  <input
                    type="number"
                    min="0"
                    v-model="block.style.detailListFontSize"
                    class="form-control-sm px-2 ml-2 input-border-style"
                    style="width: 50px; max-width: 50px"
                  />
                </div>
                <div class="d-flex align-items-center">
                  <span
                    title="Bold"
                    class="mr-1 cursorPointer font-style-button"
                    :class="{
                      'active-svg': block.style.detailListFontWeight === 'bold',
                    }"
                    @click="
                      block.style.detailListFontWeight =
                        block.style.detailListFontWeight == 'normal'
                          ? 'bold'
                          : 'normal'
                    "
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 10 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.00001 5.99165C8.80834 5.43331 9.37501 4.51665 9.37501 3.66665C9.37501 1.78331 7.91668 0.333313 6.04168 0.333313H0.833344V12H6.70001C8.44168 12 9.79168 10.5833 9.79168 8.84165C9.79168 7.57498 9.07501 6.49165 8.00001 5.99165ZM3.33334 2.41665H5.83334C6.52501 2.41665 7.08334 2.97498 7.08334 3.66665C7.08334 4.35831 6.52501 4.91665 5.83334 4.91665H3.33334V2.41665ZM6.25001 9.91665H3.33334V7.41665H6.25001C6.94168 7.41665 7.50001 7.97498 7.50001 8.66665C7.50001 9.35831 6.94168 9.91665 6.25001 9.91665Z"
                        fill="#B3AFB6"
                      />
                    </svg>
                  </span>
                  <span
                    title="Italic"
                    class="mx-1 cursorPointer font-style-button"
                    :class="{
                      'active-svg':
                        block.style.detailListFontStyle === 'italic',
                    }"
                    @click="
                      block.style.detailListFontStyle =
                        block.style.detailListFontStyle == 'normal'
                          ? 'italic'
                          : 'normal'
                    "
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 10 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.33333 0.333313V2.83331H5.175L2.325 9.49998H0V12H6.66667V9.49998H4.825L7.675 2.83331H10V0.333313H3.33333Z"
                        fill="#B3AFB6"
                      />
                    </svg>
                  </span>
                  <span
                    title="Underline"
                    class="mx-1 cursorPointer font-style-button"
                    :class="{
                      'active-svg':
                        block.style.detailListTextDecoration === 'underline',
                    }"
                    @click="
                      block.style.detailListTextDecoration =
                        block.style.detailListTextDecoration == 'underline'
                          ? 'unset'
                          : 'underline'
                    "
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 12 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.99999 12.1667C8.75832 12.1667 11 9.925 11 7.16667V0.5H8.91666V7.16667C8.91666 8.775 7.60832 10.0833 5.99999 10.0833C4.39166 10.0833 3.08332 8.775 3.08332 7.16667V0.5H0.99999V7.16667C0.99999 9.925 3.24166 12.1667 5.99999 12.1667ZM0.166656 13.8333V15.5H11.8333V13.8333H0.166656Z"
                        fill="#B3AFB6"
                      />
                    </svg>
                  </span>
                </div>
              </div>

              <div class="my-2">
                <span
                  class="sidebarElementSubTitle"
                  style="font-size: 14px; font-weight: 500"
                  >More Option Text Color</span
                >

                <div
                  class="d-flex align-items-center justify-content-between my-2"
                >
                  <div class="d-flex align-items-center">
                    <input
                      type="color"
                      v-model="block.style.detailListTextColor"
                      class="colorInput"
                    />
                    <input
                      type="text"
                      class="form-control-sm px-2 ml-2 input-border-style"
                      style="max-width: 80px"
                      v-model="block.style.detailListTextColor"
                    />
                  </div>

                  <div class="d-flex align-items-center">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 14 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
                        fill="#B3AFB6"
                      />
                    </svg>

                    <input
                      type="number"
                      min="0"
                      v-model="block.style.detailListTextOpacity"
                      style="max-width: 80px"
                      class="form-control-sm px-2 ml-2 input-border-style"
                    />
                  </div>
                </div>
              </div>

              <div class="my-2">
                <span
                  class="sidebarElementSubTitle"
                  style="font-size: 14px; font-weight: 500"
                  >More Option Text</span
                >

                <div class="d-flex align-items-center mt-2">
                  <input
                    type="text"
                    v-model="block.moreOptionText"
                    class="form-control-sm w-100 px-2 input-border-style"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Text Style Accordion End -->
      <!--Button Style Accordion Start -->
      <div class="my-2 pb-3" style="border-bottom: 1px solid #18191c14">
        <div
          class="d-flex align-items-center justify-content-between cursor-pointer"
          @click="updateSelectedBlock('buttonSettings')"
        >
          <span class="sidebarElementTitle">Button Settings</span>
          <div>
            <svg
              v-if="selectedBlock == 'buttonSettings'"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.8167 12.8417L10 9.025L6.175 12.8417L5 11.6667L10 6.66667L15 11.6667L13.8167 12.8417Z"
                fill="#73738D"
              />
            </svg>
            <svg
              v-else
              style="transform: rotate(180deg)"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.8167 12.8417L10 9.025L6.175 12.8417L5 11.6667L10 6.66667L15 11.6667L13.8167 12.8417Z"
                fill="#73738D"
              />
            </svg>
          </div>
        </div>
        <div class="my-2" v-if="selectedBlock == 'buttonSettings'">
          <div class="mt-1 d-flex align-items-center justify-content-between">
            <span
              class="sidebarElementSubTitle"
              style="font-size: 14px; font-weight: 500"
              >Add to Cart</span
            >
            <div
              class="show-logo custom-control custom-switch cursor-pointer"
              @click="openUpgradePlansModalForAddToCart('variant')"
            >
              <input
                type="checkbox"
                class="custom-control-input"
                :id="`addToCart-${Date.now()}`"
                :disabled="!showAddToCartAndVariant"
                v-model="block.useAddToCart"
              />

              <label
                class="custom-control-label"
                :for="`addToCart-${Date.now()}`"
              ></label>
            </div>
          </div>
          <div class="d-flex align-items-center my-2">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              title="Font"
            >
              <path
                d="M2.08333 3.33334V5.83334H6.24999V15.8333H8.74999V5.83334H12.9167V3.33334H2.08333ZM17.9167 7.50001H10.4167V10H12.9167V15.8333H15.4167V10H17.9167V7.50001Z"
                fill="#B3AFB6"
              />
            </svg>

            <select
              class="w-100 newSelectInput ml-2"
              aria-label="Default select example"
              v-model="block.style.buttonFontFamily"
            >
              <option
                v-for="font in getFontFamilyList"
                :key="font.value"
                :value="font.value"
              >
                {{ font.value }}
              </option>
            </select>
          </div>
          <div class="my-2">
            <span
              class="sidebarElementSubTitle"
              style="font-size: 14px; font-weight: 500"
              >Border</span
            >

            <div class="d-flex align-items-center justify-content-between mt-2">
              <div class="d-flex align-items-center">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.8333 13.8333H15.5V15.5H13.8333V13.8333ZM13.8333 12.1667H15.5V10.5H13.8333V12.1667ZM0.5 8.83333H2.16667V7.16667H0.5V8.83333ZM0.5 12.1667H2.16667V10.5H0.5V12.1667ZM0.5 5.5H2.16667V3.83333H0.5V5.5ZM0.5 2.16667H2.16667V0.5H0.5V2.16667ZM3.83333 2.16667H5.5V0.5H3.83333V2.16667ZM10.5 15.5H12.1667V13.8333H10.5V15.5ZM7.16667 15.5H8.83333V13.8333H7.16667V15.5ZM10.5 15.5H12.1667V13.8333H10.5V15.5ZM3.83333 15.5H5.5V13.8333H3.83333V15.5ZM0.5 15.5H2.16667V13.8333H0.5V15.5ZM15.5 4.66667C15.5 2.36667 13.6333 0.5 11.3333 0.5H7.16667V2.16667H11.3333C12.7083 2.16667 13.8333 3.29167 13.8333 4.66667V8.83333H15.5V4.66667Z"
                    fill="#B3AFB6"
                  />
                </svg>

                <input
                  type="number"
                  min="0"
                  max="50"
                  v-model="block.style.buttonBorderRadius"
                  class="form-control-sm px-2 ml-2 input-border-style"
                  style="width: 50px; max-width: 50px"
                />
              </div>
              <div class="d-flex align-items-center">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.5 2.16667V13.8333C0.5 14.75 1.25 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5H2.16667C1.25 0.5 0.5 1.25 0.5 2.16667ZM13 13.8333H3C2.54167 13.8333 2.16667 13.4583 2.16667 13V3C2.16667 2.54167 2.54167 2.16667 3 2.16667H13C13.4583 2.16667 13.8333 2.54167 13.8333 3V13C13.8333 13.4583 13.4583 13.8333 13 13.8333Z"
                    fill="#B3AFB6"
                  />
                </svg>

                <input
                  type="number"
                  v-model="block.style.buttonBorderSize"
                  min="0"
                  class="form-control-sm px-2 ml-2 input-border-style"
                  style="width: 50px; max-width: 50px"
                />
              </div>
            </div>
            <div class="d-flex align-items-center mt-2 ml-n2">
              <input
                type="color"
                v-model="block.style.buttonBorderColor"
                class="colorInput"
                v-b-tooltip.hover
                title="Button Border Color"
              />
              <input
                type="text"
                class="form-control-sm px-2 ml-2 input-border-style"
                style="max-width: 80px"
                v-model="block.style.buttonBorderColor"
              />
            </div>
          </div>

          <div class="my-2">
            <span
              class="sidebarElementSubTitle"
              style="font-size: 14px; font-weight: 500"
              >Backgorund Color</span
            >

            <div class="d-flex align-items-center mt-2">
              <input
                type="color"
                v-model="block.style.buttonBgColor"
                class="colorInput"
              />
              <input
                type="text"
                class="form-control-sm px-2 ml-2 input-border-style"
                style="max-width: 80px"
                v-model="block.style.buttonBgColor"
              />
            </div>
          </div>
          <div class="my-2">
            <span
              class="sidebarElementSubTitle"
              style="font-size: 14px; font-weight: 500"
              >Text Color</span
            >

            <div class="d-flex align-items-center mt-2">
              <input
                type="color"
                v-model="block.style.buttonTextColor"
                class="colorInput"
              />
              <input
                type="text"
                class="form-control-sm px-2 ml-2 input-border-style"
                style="max-width: 80px"
                v-model="block.style.buttonTextColor"
              />
            </div>
          </div>
          <div class="d-flex align-items-center my-2">
            <svg
              width="19"
              height="13"
              viewBox="0 0 19 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z"
                fill="#B3AFB6"
              />
              <path
                d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z"
                fill="#B3AFB6"
              />
            </svg>

            <input
              type="number"
              min="0"
              v-model="block.style.buttonDesktopFontSize"
              class="form-control-sm px-2 ml-4 input-border-style"
              style="width: 50px; max-width: 50px"
            />
          </div>
          <div class="my-2">
            <span
              class="sidebarElementSubTitle"
              style="font-size: 14px; font-weight: 500"
              >Text</span
            >

            <div class="d-flex align-items-center mt-2">
              <input
                v-if="block.useAddToCart"
                type="text"
                v-model="block.useAddToCartText"
                class="form-control-sm w-100 px-2 input-border-style"
              />
              <input
                v-else
                type="text"
                v-model="block.buttonText"
                class="form-control-sm w-100 px-2 input-border-style"
              />
            </div>
          </div>
        </div>
      </div>

      <!--Button Style Accordion End -->
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import Button from "../../../../Dashboard/Layout/Button.vue";
import { mapGetters, mapMutations } from "vuex";
import fontFamilyList from "../../../staticPages/fontFamilyList.js";
export default {
  components: { Multiselect, Button },
  props: {
    block: Object,
    index: Number,
    isDesktop: Boolean,
  },
  data() {
    return {
      count: 0,
      rankOptionArr: [],
      hidePrice: null,
      isFetchingGroupsLoading: false,
      groupProducts: [],
      selectedBlock: "",
    };
  },
  mounted() {
    this.rankOptionArr = this.block?.productRankOption || [];
    this.hidePrice = this.getEditorSettings.hidePrice;
  },
  methods: {
    updateSelectedBlock(block) {
      if (block == this.selectedBlock) {
        this.selectedBlock = "";
        return;
      }
      this.selectedBlock = block;
    },
    setViewType(value) {
      if (this.block.useSeperateMobileViewType && !this.isDesktop) {
      this.block.mobileViewType = value;
    } else {
      this.block.viewType = value;
    }
    },
    assignGroupProduct() {
      if (this.isUpperPlanUser) {
        this.block.viewType = "groupProducts";
      } else {
        let obj = {
          isOpen: true,
          title: "Upgrade to Utilize Group Product Feature",
          subText: `This enhancement lets you categorize products by tags, offering a more organized and targeted display in your quiz results. Tailor your quizzes to showcase products in distinct groups, enriching the user experience. For a comprehensive guide on implementing this feature, please visit: <a href="https://support.quizell.com/blogs/how-to-use/how-to-use-the-group-products-feature" target="_blank" style="color:#FFC000;text-decoration:underline">How to Use the Group Product Feature</a>.`,
        };

        this.setUpgradePlansModal(obj);
      }
    },
    updateHidePriceFunc() {
      this.updatehidePrice(this.hidePrice);
    },
    ...mapMutations([
      "setUpgradePlansModal",
      "updatehidePrice",
      "setUpgradePlansModal",
    ]),
    openUpgradePlansModalForAddToCart(mode) {
      if (!this.showAddToCartAndVariant) {
        let obj;
        if (mode == "cart") {
          obj = {
            isOpen: true,
            title: "Upgrade for Direct Add to Cart",
            subText: `Enhance your quiz functionality by upgrading to include the 'Add to Cart' feature. This powerful tool allows quiz takers to directly add products to their shopping cart while engaging with your quiz`,
          };
        } else {
          obj = {
            isOpen: true,
            title: "Upgrade Your Plan To Access More!",
            subText: `It seems you're trying to use a feature that isn't included in your current subscription plan. To unlock this and more amazing features, please consider upgrading your account.`,
          };
        }

        this.setUpgradePlansModal(obj);
      }
    },
    goToConfiguration(selectedTab) {
      this.$router.push({
        name: "Connect",
        query: { selectedTab: selectedTab },
      });
    },
    checkHexCode(color) {
      if (color) {
        let pattern = /^#[0-9A-F]{6}$/i;
        return color.match(pattern) ? true : false;
      }
    },
    BgColorOutput(value) {
      let isHex = this.checkHexCode(value);
      if (isHex) {
        this.showBgOpacity = true;
        return value;
      } else {
        this.showBgOpacity = false;
        return "Gradient input";
      }
    },
    deleteRankOption(index) {
      this.$emit("deleteSingleProductRankOption", index);
    },
    addRankOption() {
      this.$emit("AddSingleProductRankOption");
    },
    hasKeyValuePairs(obj, keyValuePairs) {
      for (const [key, value] of Object.entries(keyValuePairs)) {
        if (!(key in obj) || obj[key] !== value) {
          return false;
        }
      }

      return true;
    },

    async getGroups() {
      try {
        this.isFetchingGroupsLoading = true;
        let payload = {};
        if (this.getGroupBy.toLowerCase() == "tags") {
          payload.filters = ["tags"];
        } else {
          payload.filters = ["collections"];
        }

        const response = await axios.post(`/result/filter/group`, payload);
        if (response.status == 200) {
          if (this.getGroupBy.toLowerCase() == "tags") {
            this.groupProducts = response.data.data.tags;
          } else {
            this.groupProducts = response.data.data.collections;
          }

          // this.groupProducts = response.data.data;
        }
      } catch (error) {
        if (error) {
          console.log("Error", error);
        }
      } finally {
        this.isFetchingGroupsLoading = false;
      }
    },
  },
  computed: {
    getFontFamilyList() {
      return fontFamilyList;
    },
    ...mapGetters([
      "getPlanData",
      "getNavbarData",
      "getEditorSettings",
      "getQuizID",
    ]),
    getEnv() {
      return this.getEditorSettings.env_contants;
    },
    getCurrQuizId() {
      return this.getQuizID;
    },
    checkIsCustomCollectionMedisClient() {
      return (
        this.getCurrQuizId == this.getEnv?.VUE_APP_COLLECTION_CLIENT_QUIZ_ID ??
        null
      );
    },
    UserPlanData() {
      return this.getPlanData;
    },
    isUpperPlanUser() {
      let allowedPlan = ["Pro", "Plus", "Enterprise plan Special"];
      return allowedPlan.includes(this.UserPlanData.name);
    },
    getViewTypeValue() {

      if (this.block.useSeperateMobileViewType && !this.isDesktop) {
     return  this.block.mobileViewType ;
    } else {
      return this.block.viewType;
    }
    },
    getUserType() {
      return this.getNavbarData.userType;
    },
    showAddToCartAndVariant() {
      let allowedPlan = ["Pro", "Plus", "Enterprise plan Special"];
      return (
        allowedPlan.includes(this.UserPlanData.name) &&
        this.getUserType == "shopify"
      );
    },
    getGroupBy() {
      return this.block?.groupProducts.groupBy;
    },
    getGroupOptions() {
      // return this.groupProducts.length > 0 ?    : []

      if (this.groupProducts.length > 0) {
        let groupOptions = [...this.groupProducts];

        return groupOptions.map((x) => x.title);
      }
      return [];
    },
    textBlockFont(){
      return this.block.style.fontFamily
    },
    groupProductsFont(){
      return this.block.style.groupProductsStyle.fontFamily
    },
    bulletDescriptionFont(){
      return this.block.style.bulletDescriptionfontFamily
    },
    productVariantFont(){
      return this.block.style.productVariantStyles.fontFamily
    },
    detailListFont(){
      return this.block.style.detailListFontFamily
    },
    buttonFont(){
      return this.block.style.buttonFontFamily
    },
  },

  watch: {
    getViewTypeValue: {
      handler(newValue) {
        if (newValue == "groupProducts") {
          this.getGroups();
        }
      },
      immediate: true,
    },
    getGroupBy: {
      handler(newValue) {
        if (newValue && this.getViewTypeValue == "groupProducts") {
          this.getGroups();
        }
      },
      // immediate: true,
    },
    textBlockFont(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    },
    groupProductsFont(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    },
    bulletDescriptionFont(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    },
    productVariantFont(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    },
    detailListFont(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    },
    buttonFont(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    },

  },
};
</script>
<style>
.Modal-btn:hover {
  color: #ffffff;
}

a[aria-expanded="true"] i {
  transform: rotate(180deg);
}
.arrow-icon-down {
  color: #000000 !important;
}
.headingText strong {
  font-size: 14px;
  font-weight: 500 !important;
}
.reset-option {
  border: 0.3px solid #dbd5d5;
  box-sizing: border-box;
  border-radius: 4px;
}

.text-setting select {
  font-size: 14px;
  padding: 0;
}

.input-border-style {
  border: 0.5px solid #dfdfdf !important;
}
.input-border-style:focus-visible {
  outline: none !important;
}
.button-group {
  color: #535353;
  outline: #c4c4c4 solid 1px;
}
.button-selected {
  background: #000000 !important;
  color: #ffffff !important;
}

.font-style-button {
  width: 28px;
  height: 28px;
  background: #f2f2f2;
  border-radius: 2px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}
.custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff !important;
}
/* Button Group CSS Style */

.productViewSelected {
  background: #ffa201 !important;
  color: #ffffff !important;
}

.GroupSelectContainer .multiSelect {
  text-align: start;
  direction: ltr !important;
}

.GroupSelectContainer > .multiSelect .multiselect__placeholder {
  color: #1f1f28;
  font-weight: 400;
  font-size: 14px;
}

.GroupSelectContainer > .multiselect .multiselect__tag {
  background: #ffa201 !important;
  max-width: 220px;
}
.GroupSelectContainer > .multiselect .multiselect__tag-icon:after {
  color: #ffffff !important;
}

.GroupSelectContainer > .multiselect__content-wrapper {
  text-align: left !important;
  direction: ltr !important;
  z-index: 100;
  position: relative;
}
/* DropdownStyles */
.GroupSelectContainer > .multiselect .multiselect__option--highlight,
.GroupSelectContainer > .multiselect .multiselect__option--highlight:after {
  background: #4d1b7e !important;
}

.productCardSVG.selected path,
.imageRatioSVG.selected path {
  fill: #4d1b7e;
}

.colorInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 30px !important;
  height: 30px !important;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 2px !important;
  margin-right: 5px;
  border-radius: 4px;
}
.colorInput::-webkit-color-swatch {
  border: 2px solid #cfcece;
  border-radius: 4px;
}
.colorInput::-moz-color-swatch {
  border: 2px solid #cfcece;
  border-radius: 4px;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>